import { AvailableTimeModel, CreateReservationCommand } from ".";
import api from "../api";
import { AxiosResponse } from "axios";

export async function createReservation(
  restaurantId: number,
  branchId: number,
  command: CreateReservationCommand
): Promise<string> {
  try {
    const response: AxiosResponse<string> = await api.post(
      `Reservation?restaurantId=${restaurantId}&branchId=${branchId}`,
      command
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAvailableTimes(
  branchId: number,
  date: Date,
  personCount: number
): Promise<AvailableTimeModel[]> {
  try {
    const response: AxiosResponse<AvailableTimeModel[]> = await api.get(
      `/Reservation/AvailableTimes/${branchId}?date=${date.toISOString()}&personCount=${personCount}`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting for reservation:", error);
    throw error;
  }
}
