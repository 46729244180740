import React from "react";
import styles from "./loader.module.scss";

interface LoaderProps {
  width?: string;
  height?: string;
}

const Loader: React.FC<LoaderProps> = ({ width, height }) => {
  const loaderStyle = {
    width: width || "30px",
    height: height || "30px",
  };

  return (
    <div className={styles.loader} style={loaderStyle}>
      {/* <div className={styles.spinner}></div> */}
    </div>
  );
};

export default Loader;
