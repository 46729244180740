import { AxiosResponse } from "axios";
import { AuthResponse } from "./model";
import api from "../../api";

export async function auth(
  phoneNumber: string,
  otp: string
): Promise<AuthResponse> {
  try {
    const response: AxiosResponse<AuthResponse> = await api.post(
      `/Auth/Account/${phoneNumber}/${otp}`
    );
    return response.data;
  } catch (error) {
    console.error("Error login", error);
    throw error;
  }
}

export async function sendOtp(phoneNumber: string): Promise<void> {
  try {
    await api.post(`/Auth/Account/otp/${phoneNumber}`);
  } catch (error) {
    console.error("Error sending otp", error);
    throw error;
  }
}
