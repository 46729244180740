"use client";

import styles from "./date-guest-picker.module.scss";
import { useState } from "react";
import React from "react";
import { RestauranntToReserve } from "../../../api/restaurant";
import Calendar from "../calendar/calendar";
import GuestPicker from "../guest-picker/guest-picker";
import Loader from "../../loader/loder";

interface DateGuestPickerProps {
  date: Date;
  setDate: (date: Date) => void;
  guest: number;
  setGuest: (guest: number) => void;
  isLoading: boolean;
  onSubmited: () => void;
  restaurant: RestauranntToReserve;
  branch: string | null;
}

const DateGuestPicker: React.FC<DateGuestPickerProps> = ({
  date,
  setDate,
  guest,
  setGuest,
  isLoading,
  onSubmited,
  restaurant,
}) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [guestPickerOpen, setGuestPickerOpen] = useState<boolean>(false);

  const maxGuests = 8;

  const increaseGuests = () => {
    if (guest >= maxGuests) {
      return;
    }

    setGuest(guest + 1);
  };

  const decreaseGuests = () => {
    const newGuestCount = Math.max(guest - 1, 1);

    setGuest(newGuestCount);
  };

  const increaseDate = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);

    setDate(newDate);
  };

  const decreaseDate = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);

    const today = new Date();
    if (newDate >= today) {
      setDate(newDate);
    } else {
      setDate(today);
    }
  };

  const handleDateChange = (date: Date) => {
    setDate(date);

    setCalendarOpen(false);
  };

  const handleGuestChange = (guest: number) => {
    setGuest(guest);

    setGuestPickerOpen(false);
  };

  const formatDate = (date: Date) => {
    const dayNames = ["კვ", "ორშ", "სამ", "ოთხ", "ხუთ", "პარ", "შაბ"];
    const monthNames = [
      "იან",
      "თებ",
      "მარ",
      "აპრ",
      "მაი",
      "ივნ",
      "ივლ",
      "აგვ",
      "სექ",
      "ოქტ",
      "ნოე",
      "დეკ",
    ];

    return `${dayNames[date.getDay()]}, ${date.getDate()} ${
      monthNames[date.getMonth()]
    }`;
  };

  return (
    <>
      <div
        onClick={() => {
          if (calendarOpen) setCalendarOpen(false);
          if (guestPickerOpen) setGuestPickerOpen(false);
        }}
      >
        <div className={styles.header}>
          <p>{restaurant.displayName}</p>
        </div>

        <div className={styles.form}>
          <div className={styles.formComponent}>
            <div className={styles.input}>
              <i
                onClick={decreaseDate}
                className={`ri-arrow-left-s-line ${styles.icon}`}
              ></i>
              <button
                onClick={() => {
                  setCalendarOpen(true);
                }}
                className={styles.value}
              >
                {formatDate(date)}
              </button>
              <i
                onClick={increaseDate}
                className={`ri-arrow-right-s-line ${styles.icon}`}
              ></i>
            </div>
            თარიღი
          </div>
          <div className={styles.formComponent}>
            <div className={styles.input}>
              <i
                onClick={decreaseGuests}
                className={`ri-arrow-left-s-line ${styles.icon}`}
              ></i>
              <button
                onClick={() => {
                  setGuestPickerOpen(true);
                }}
                className={styles.value}
              >
                {guest}
              </button>

              <i
                onClick={increaseGuests}
                className={`ri-arrow-right-s-line ${styles.icon}`}
              />
            </div>
            სტუმარი
          </div>
          <div className={styles.formComponent}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={onSubmited}
                className={styles.buttonComponent}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader width="14px" height="14px" />
                ) : (
                  "ხელმისაწვდომი დროები"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {calendarOpen && <Calendar startDate={date} setDate={handleDateChange} />}
      {guestPickerOpen && (
        <GuestPicker
          maxGuest={maxGuests}
          guest={guest}
          setGuest={handleGuestChange}
        />
      )}
    </>
  );
};

export default DateGuestPicker;
