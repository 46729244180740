import React, { createContext, useContext, useState, ReactNode } from 'react';
import AuthModal from './auth-modal';

interface AuthModalContextProps {
  openAuthModal: () => void;
  closeAuthModal: () => void;
}

interface AuthModalProviderProps {
  children: ReactNode;
}

const AuthModalContext = createContext<AuthModalContextProps | undefined>(
  undefined
);

export const AuthModalProvider: React.FC<AuthModalProviderProps> = ({ children }) => {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const openAuthModal = () => {
    setAuthModalOpen(true);
  };

  const closeAuthModal = () => {
    setAuthModalOpen(false);
  };

  return (
    <AuthModalContext.Provider value={{ openAuthModal, closeAuthModal }}>
      {children}
      <AuthModal isOpen={isAuthModalOpen} onClose={closeAuthModal} />
    </AuthModalContext.Provider>
  );
};

export const useAuthModal = (): AuthModalContextProps => {
  const context = useContext(AuthModalContext);

  if (!context) {
    throw new Error('useAuthModal must be used within an AuthModalProvider');
  }

  return context;
};
