"use client";

import styles from "./small-header.module.scss";
import React from "react";
import ArrowLeftSVG from "../../../assets/icons/arrow-left.svg";

interface SmallHeaderProps {
  date: Date;
  guest: number;
  onBack: () => void;
}

const SmallHeader: React.FC<SmallHeaderProps> = ({ date, guest, onBack }) => {
  const formatDate = (date: Date) => {
    const dayNames = ["კვ", "ორშ", "სამ", "ოთხ", "ხუთ", "პარ", "შაბ"];
    const monthNames = [
      "იან",
      "თებ",
      "მარ",
      "აპრ",
      "მაი",
      "ივნ",
      "ივლ",
      "აგვ",
      "სექ",
      "ოქტ",
      "ნოე",
      "დეკ",
    ];

    return `${dayNames[date.getDay()]}, ${date.getDate()} ${
      monthNames[date.getMonth()]
    }`;
  };

  return (
    <div className={styles.smallHeader}>
      <img src={ArrowLeftSVG} alt="Arr" onClick={onBack} />
      <div
        style={{
          width: "100%",
          textAlign: "center",
          color: "#2f372d",
        }}
      >
        <p>
          {formatDate(date)} - {guest} სტუმარი
        </p>
      </div>
    </div>
  );
};

export default SmallHeader;
