import React from "react";
import { useAuthModal } from "./components/auth/auth-modal-context";

interface TestPageProps {
  subdomain: string | null;
}

const TestPage: React.FC<TestPageProps> = ({ subdomain }) => {
  const { openAuthModal } = useAuthModal();
  return (
    <div>
      <h1>Test Page</h1>
      {subdomain ? (
        <p>Subdomain: {subdomain}</p>
      ) : (
        <p>No subdomain detected.</p>
      )}
      <button onClick={openAuthModal}>Open Auth Modal</button>
    </div>
  );
};

export default TestPage;
