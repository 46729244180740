import styles from "./guest-picker.module.scss";

interface GuestPickerProps {
  maxGuest: number;
  guest?: number;
  setGuest: (guest: number) => void;
}

const GuestPicker: React.FC<GuestPickerProps> = ({
  maxGuest,
  guest,
  setGuest,
}) => {
  return (
    <div className={styles.guetPicker}>
      <div className={styles.header}>
        <p>აირჩიე სასურველი რაოდენობა</p>
      </div>
      <div className={styles.guests}>
        {Array.from({ length: maxGuest }, (_, index) => index + 1).map((gs) => (
          <button
            key={gs}
            className={`${styles.guest} ${
              guest === gs ? styles.choosedGuest : ""
            }`}
            onClick={() => setGuest(gs)}
          >
            <p>{gs}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default GuestPicker;
