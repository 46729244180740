"use client";

import styles from "./branch-picker.module.scss";
import { useState } from "react";
import React from "react";
import Select from "react-select";
import { Branch, RestauranntToReserve } from "../../../api/restaurant";

interface BranchPickerProps {
  restaurant: RestauranntToReserve;
  branchDomainName: string | null;
  onChooseBranch: (branch: Branch) => void;
}

const BranchPicker: React.FC<BranchPickerProps> = ({
  restaurant,
  branchDomainName,
  onChooseBranch,
}) => {
  const [choosedBranch, setChoosedBranch] = useState<Branch | null>(
    branchDomainName
      ? restaurant.branches.find(
          (branch) => branch.domainName === branchDomainName
        ) || null
      : null
  );

  const options = restaurant.branches.map((branch) => ({
    value: branch.domainName,
    label: branch.displayName,
  }));

  const handleSelectChange = (selectedOption: any) => {
    var newChoosedBranch = restaurant.branches.find(
      (branch) => branch.domainName === selectedOption.value
    );
    setChoosedBranch(newChoosedBranch ?? null);
  };

  const handleChooseBranch = () => {
    if (choosedBranch) {
      onChooseBranch(choosedBranch);

      const queryParams = `?branch=${encodeURIComponent(
        choosedBranch.domainName
      )}`;
      window.history.replaceState({}, "", queryParams);
    }
  };

  return (
    <>
      <div
        style={{
          width: "400px",
          margin: "auto",
          padding: "50px 0px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "80%",
        }}
      >
        <Select
          className="basic-single"
          classNamePrefix="select"
          name="Brances"
          options={options}
          isSearchable={true}
          onChange={handleSelectChange}
          placeholder="აიჩიე ფილიალი"
          styles={{
            placeholder: (provided) => ({
              ...provided,
              color: "#F7E6C4",
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "#606C5D",
              color: "#F7E6C4",
              borderColor: "none",
              borderWidth: "0px",
              boxShadow: "none",
              borderRadius: "7px",
              ":hover": {
                borderColor: "darkgray",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "#2F372D" : "#606C5D",
              color: "#F7E6C4",
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "#F7E6C4",
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: "#606C5D",
            }),
          }}
        />
        <button
          onClick={handleChooseBranch}
          className={styles.chooseBranchButton}
          disabled={!choosedBranch}
        >
          გაგრძელება
        </button>
      </div>
    </>
  );
};

export default BranchPicker;
