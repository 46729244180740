import React, { useRef, useState } from "react";
import { AuthResponse, auth } from "../../../api/auth/account";
import { authService } from "../../../services/authService";
import Loader from "../../loader/loder";
import style from "./otp-input.module.scss";

interface OTPInputProps {
  phoneNumber: string;
  onClose: () => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ phoneNumber, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = Array.from({ length: 4 }, () =>
    useRef<HTMLInputElement>(null)
  );

  const handleOtpInputChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
    }

    if (value === "" && index > 0 && inputRefs[index - 1]?.current) {
      inputRefs[index - 1].current?.focus();
    } else {
      focusNextInput(index);
    }
  };

  const focusNextInput = (currentIndex: number) => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < 4 && inputRefs[nextIndex]?.current) {
      inputRefs[nextIndex].current?.focus();
    }
  };

  const handleOtpSubmit = () => {
    const enteredOtp = otpValues.join("");

    setLoading(true);
    auth(phoneNumber, enteredOtp)
      .then((data: AuthResponse) => {
        authService.login(data);
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disable = otpValues.every((value) => value !== "");

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          gap: "10px",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            color: "#2F372D",
            padding: "10px",
          }}
        >
          შეიყვანეთ OTP
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {[0, 1, 2, 3].map((index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#EDD0AA",
                border: "2px solid #0D5730",
                borderRadius: "10px",
                color: "#0d5730",
                padding: "5px",
                width: "25px",
              }}
            >
              <input
                type="text"
                id={`otp${index}`}
                name={`otp${index}`}
                maxLength={1}
                ref={inputRefs[index]}
                onChange={(e) => handleOtpInputChange(index, e.target.value)}
                style={{
                  border: "none",
                  background: "transparent",
                  color: "#0d5730",
                  outline: "none",
                  textAlign: "center",
                  width: "100%",
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <button
          className={style.button}
          disabled={!disable || loading}
          onClick={handleOtpSubmit}
        >
          {loading ? (
            <Loader height="8px" width="8px" />
          ) : (
            <span style={{ color: "#0d5730" }}>დადასტურება</span>
          )}
        </button>
      </div>
    </>
  );
};

export default OTPInput;
