import { RestauranntToReserve } from ".";
import api from "../api";
import { AxiosResponse } from "axios";

export async function getRestaurantToReserve(
  restaurantDomainName: string
): Promise<RestauranntToReserve> {
  try {
    const response: AxiosResponse<RestauranntToReserve> = await api.get(
      `/Restaurant/Reserve/${restaurantDomainName}`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting for restaurant:", error);
    throw error;
  }
}
