import React, { useState } from "react";
import Modal from "react-modal";
import OTPInput from "./otp-input/otp-input";
import PhoneInput from "./phone-input/phone-input";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSentOtp = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    setStep(2);
  };

  const modalStyles = {
    content: {
      overflow: "hidden",
      width: "300px",
      height: "250px",
      margin: "auto",
      background:
        "linear-gradient(180deg, #DB9756 0%, rgba(231, 184, 132, 0.9) 52.08%)",
      border: "none",
      borderRadius: "10px",
    },
    overlay: {
      backgroundColor: "rgba(247, 230, 196, 0.8)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="ავტორიზაცია"
      style={modalStyles}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "25px",
          padding: "20px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              marginRight: "10px",
              fontSize: "20px",
              color: "#2F372D",
              fontWeight: "bold",
            }}
          >
            ავტორიზაცია
          </span>
        </div>

        {step === 1 && <PhoneInput moveNext={handleSentOtp} />}
        {step === 2 && <OTPInput phoneNumber={phoneNumber} onClose={onClose} />}
      </div>
    </Modal>
  );
};

export default AuthModal;
