import { useState } from "react";
import styles from "./calendar.module.scss";

interface CalendarProps {
  startDate?: Date;
  setDate: (date: Date) => void;
}

const Calendar: React.FC<CalendarProps> = ({ startDate, setDate }) => {
  const [currentDate, setCurrentDate] = useState(startDate ?? new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getStartDay = (year: number, month: number): number => {
    return new Date(year, month, 1).getDay();
  };

  const getDaysArray = (
    daysInMonth: number,
    startDay: number
  ): (number | null)[] => {
    const emptyDays: (number | null)[] = Array.from(
      { length: startDay === 0 ? 6 : startDay - 1 },
      () => null
    );

    const daysArray: number[] = Array.from(
      { length: daysInMonth },
      (_, i) => i + 1
    );
    return [...emptyDays, ...daysArray];
  };

  const handleDayClick = (day: number) => {
    const newSelectedDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );

    if (selectedDate && newSelectedDay.getTime() === selectedDate.getTime()) {
      setSelectedDate(null);
      return;
    }

    if (newSelectedDay < new Date()) {
      return;
    }

    setSelectedDate(newSelectedDay);
    setDate(newSelectedDay);
  };

  const daysInMonth: number = getDaysInMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  const startDay: number = getStartDay(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  const daysArray: (number | null)[] = getDaysArray(daysInMonth, startDay);

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <button onClick={prevMonth}>&lt;</button>
        <h2>
          {currentDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <button onClick={nextMonth}>&gt;</button>
      </div>
      <div className={styles.days}>
        {["ორშ", "სამ", "ოთხ", "ხუთ", "პარ", "შაბ", "კვ"].map(
          (dayOfWeek, index) => (
            <div key={index} className={styles.dayOfWeek}>
              {dayOfWeek}
            </div>
          )
        )}

        {daysArray.map((day, index) =>
          day !== null ? (
            <div
              key={index}
              className={`${styles.day} ${
                startDate &&
                startDate.getDate() === day &&
                startDate.getMonth() === currentDate.getMonth() &&
                startDate.getFullYear() === currentDate.getFullYear()
                  ? styles.selectedDay
                  : ""
              } ${
                new Date().getDate() === day &&
                new Date().getMonth() === currentDate.getMonth() &&
                new Date().getFullYear() === currentDate.getFullYear()
                  ? styles.nowDay
                  : ""
              } ${
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  day
                ) < new Date()
                  ? styles.pastDay
                  : ""
              }`}
              onClick={() => day && handleDayClick(day)}
            >
              {day}
            </div>
          ) : (
            <div key={index}></div>
          )
        )}
      </div>
    </div>
  );
};

export default Calendar;
