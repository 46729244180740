import axios from "axios";
import { authService } from "../services/authService";

const api = axios.create({
  // baseURL: "https://localhost:5000/api/v1",
  baseURL: "https://easydine-api.azurewebsites.net/api/v1",
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false,
  // }),
  headers: {
    "X-Channel": "ReserveUI",
  },
});

api.interceptors.request.use(
  function (config) {
    const token = authService.getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
