"use client";

import styles from "./available-times-picker.module.scss";
import React from "react";
import SmallHeader from "../small-header/small-header";
import { AvailableTimeModel } from "../../../api/reservation";
import { RestauranntToReserve } from "../../../api/restaurant";

interface AvailableTimesPickerProps {
  date: Date;
  guest: number;
  restaurant: RestauranntToReserve;
  branch: string | null;
  availableTimes: AvailableTimeModel[];
  onChooseTime: (time: AvailableTimeModel) => void;
  onBack: () => void;
}

const AvailableTimesPicker: React.FC<AvailableTimesPickerProps> = ({
  date,
  guest,
  availableTimes,
  onChooseTime,
  onBack,
}) => {
  return (
    <>
      <SmallHeader date={date} guest={guest} onBack={onBack} />

      {availableTimes.length > 0 && (
        <div className={styles.form}>
          <div className={styles.availableTimes}>
            {availableTimes.map((availableTime: AvailableTimeModel, index) => (
              <button
                className={styles.time}
                onClick={() => onChooseTime(availableTime)}
                key={index}
              >
                {availableTime.formattedTime}
              </button>
            ))}
          </div>
        </div>
      )}

      {availableTimes.length == 0 && (
        <div className={styles.noAvailableTimes}>
          სამწუხაროდ, ყველა მაგიდა დაკავებულია
          {/* <div className={styles.other}>
            <div className={styles.btn}>
              <p>სხვა ხელმისაწვდომი თარიღები</p>
              <p>dow</p>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default AvailableTimesPicker;
