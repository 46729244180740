"use client";

import styles from "./reserv-form.module.scss";
import { useState } from "react";
import React from "react";
import LocationSVG from "../../../assets/icons/location.svg";
import CalendarSVG from "../../../assets/icons/calendar.svg";
import GuestsSVG from "../../../assets/icons/guests.svg";
import LabelSVG from "../../../assets/icons/label.svg";
import PencilSVG from "../../../assets/icons/pencil.svg";

import SmallHeader from "../small-header/small-header";
import { Branch, RestauranntToReserve } from "../../../api/restaurant";
import Loader from "../../loader/loder";
import { useAuthModal } from "../../auth/auth-modal-context";
import { authService } from "../../../services/authService";
import { format } from "date-fns";
import {
  CreateReservationCommand,
  createReservation,
} from "../../../api/reservation";
import { v4 as uuidv4 } from "uuid";

interface ReservFormProps {
  date: Date;
  guest: number;
  restaurant: RestauranntToReserve;
  branch: Branch;
  onBack: () => void;
}

const ReservForm: React.FC<ReservFormProps> = ({
  date,
  guest,
  restaurant,
  branch,
  onBack,
}) => {
  const [edChecked, setEDChecked] = useState<boolean>(false);
  const [restaurantChecked, setRestaurantChecked] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsLoading(true);

    const reservationCommand: CreateReservationCommand = {
      requestId: uuidv4(),
      from: date,
      personCount: guest,
      childCount: 0,
      pet: false,
      tableId: undefined,
      wantsPreorder: false,
    };

    createReservation(restaurant.id, branch.id, reservationCommand)
      .then((response: string) => {
        window.alert("Reservation created successfuly!");
        window.location.href = `https://easydine.ge/reservation/info/${response}`;
      })
      .catch((error) => {
        window.alert(`Error occured: ${error}, please try again later`);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // setFormData((prevData) => ({
    //   ...prevData,
    //   firstName: customerInfo?.firstName ?? "",
    //   lastName: customerInfo?.lastName ?? "",
    //   email: customerInfo?.email ?? "",
    //   phoneNumber: customerInfo?.phoneNumber ?? "",
    // }));
  };

  const { openAuthModal } = useAuthModal();

  const [isLoggedIn, setIsLoggedIn] = useState(authService.isLoggedIn());
  const customerInfo = isLoggedIn ? authService.getUserInfo() : null;

  const [formData, setFormData] = useState({
    firstName: customerInfo?.firstName ?? "",
    lastName: customerInfo?.lastName ?? "",
    email: customerInfo?.email ?? "",
    phoneNumber: customerInfo?.phoneNumber ?? "",
    date: "",
  });

  const checkLoginStatus = () => {
    const loggedIn = authService.isLoggedIn();
    setIsLoggedIn(loggedIn);
    if (loggedIn) {
      const userInfo = authService.getUserInfo();
      setFormData({
        firstName: userInfo?.firstName ?? "",
        lastName: userInfo?.lastName ?? "",
        email: userInfo?.email ?? "",
        phoneNumber: userInfo?.phoneNumber ?? "",
        date: "",
      });
    }
  };

  const handleOpenAuthModal = () => {
    openAuthModal();
    setInterval(checkLoginStatus, 500);
  };

  return (
    <>
      <SmallHeader date={date} guest={guest} onBack={onBack} />

      <div className={styles.form}>
        <div className={styles.userInformation}>
          <div className={styles.header}>{/* <p>თქვენი მონაცემები</p> */}</div>
          {!isLoggedIn && (
            <p style={{ textAlign: "center", fontWeight: "600" }}>
              გაიარე{" "}
              <span
                style={{
                  color: "rgb(255, 166, 41)",
                  fontWeight: "bold",
                  backgroundColor: "#f7e6c4",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOpenAuthModal();
                }}
              >
                ავტორიზაცია
              </span>{" "}
              ქულების დასაგროვებლად
            </p>
          )}

          {isLoggedIn && (
            <>
              <p style={{ textAlign: "center", fontWeight: "600" }}>
                გამარჯობა{" "}
                <span
                  style={{
                    color: "rgb(255, 166, 41)",
                    fontWeight: "bold",
                    backgroundColor: "#f7e6c4",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleOpenAuthModal();
                  }}
                >
                  {formData?.firstName},
                </span>
              </p>
              <p style={{ textAlign: "center", fontWeight: "600" }}>
                გაიარე{" "}
                <span
                  style={{
                    color: "rgb(255, 166, 41)",
                    fontWeight: "bold",
                    backgroundColor: "#f7e6c4",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openAuthModal();
                  }}
                >
                  ავტორიზაცია
                </span>{" "}
                სხვა ანგარიშით
              </p>
            </>
          )}

          {/* <div className={styles.inputs}>
            <div className={styles.ns}>
              <input
                className={styles.formInput}
                type="text"
                name="firstName"
                value={formData.firstName}
                // onChange={handleInputChange}
                placeholder="სახელი"
                disabled={isLoggedIn}
              />
              <input
                className={styles.formInput}
                type="text"
                name="lastName"
                value={formData.lastName}
                // onChange={handleInputChange}
                placeholder="გვარი"
                disabled={isLoggedIn}
              />
            </div>

            <div className={styles.in} style={{ position: "relative" }}>
              <span
                style={{
                  position: "absolute",
                  left: "8px",
                  top: "30%",
                  fontSize: "14px",
                  transform: "translateY(-50%)",
                  color: "#2F372D",
                  marginLeft: "-10px",
                }}
              >
                (+995)
              </span>
              <input
                className={styles.formInput}
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                // onChange={handleInputChange}
                placeholder="ტელეფონის ნომერი"
                style={{ paddingLeft: "45px" }}
                disabled={isLoggedIn}
              />
            </div>
            <div className={styles.in}>
              <input
                className={styles.formInput}
                type="email"
                name="email"
                value={formData.email}
                // onChange={handleInputChange}
                placeholder="მეილი"
                disabled={isLoggedIn}
              />
            </div>
            <div className={styles.in}>
              <input
                className={styles.formInput}
                type="date"
                name="firstName"
                // value={formData.firstName}
                // onChange={handleInputChange}
                placeholder="დაბადების დღე"
              />
            </div>
          </div> */}
        </div>

        <div className={styles.restaurantInformation}>
          <div className={styles.textAndIcon}>
            <img src={LocationSVG} alt="" />
            <p>{restaurant.displayName}</p>
          </div>
          <div className={styles.textAndIcon}>
            <img src={CalendarSVG} alt="" />
            <p> {format(new Date(date), "EEEE, dd HH:mm")}</p>
          </div>

          <div className={styles.textAndIcon}>
            <img src={GuestsSVG} alt="" />
            <p>{guest} სტუმარი</p>
          </div>

          <div className={styles.textAndIcon}>
            <img src={LabelSVG} alt="" />
            <p>დაბადების დღე</p>
          </div>

          <div className={styles.textAndIcon}>
            <img src={PencilSVG} alt="" />
            <p>დამატებითი ინფორმაცია</p>
          </div>
        </div>

        <div className={styles.reserve}>
          <div>
            <div className={styles.checkbox}>
              <div className={styles.container}>
                <input
                  className={styles.checkboxInput}
                  type="checkbox"
                  checked={edChecked}
                  onChange={() => {
                    setEDChecked(!edChecked);
                  }}
                />
                <span
                  className={styles.checkmark}
                  onClick={() => {
                    setEDChecked(!edChecked);
                  }}
                ></span>
              </div>
              <p>
                გავეცანი და ვეთანხმები EasyDine ის{" "}
                <a
                  href={`https://easydine.ge/terms-of-service`}
                  style={{ color: "#FFA629" }}
                  target="_blank"
                >
                  <span style={{ display: "inline" }}>წესებს და პირობებს</span>
                </a>{" "}
                და{" "}
                <a
                  href={`https://easydine.ge/privacy-policy`}
                  style={{ color: "#FFA629" }}
                  target="_blank"
                >
                  <span style={{ display: "inline" }}>
                    უსაფრთხოების პოლიტიკას
                  </span>
                </a>
              </p>
            </div>
            <div className={styles.checkbox}>
              <div className={styles.container}>
                <input
                  className={styles.checkboxInput}
                  type="checkbox"
                  checked={restaurantChecked}
                  onChange={() => {
                    setRestaurantChecked(!restaurantChecked);
                  }}
                />
                <span
                  className={styles.checkmark}
                  onClick={() => {
                    setRestaurantChecked(!restaurantChecked);
                  }}
                ></span>
              </div>
              <p>
                ვეთანხმები რესტორნის{" "}
                <a
                  href={`https://easydine.ge/reservation-policy?rDomainName=${"sd"}`}
                  style={{ color: "#FFA629" }}
                  target="_blank"
                >
                  <span style={{ display: "inline" }}>დაჯავშნის</span>
                </a>{" "}
                და{" "}
                <a
                  href={`https://easydine.ge/cancellation-policy?rDomainName=${"sd"}`}
                  style={{ color: "#FFA629" }}
                  target="_blank"
                >
                  <span style={{ display: "inline" }}>ჯავშნის გაუქმების</span>
                </a>{" "}
                პოლიტიკას.
              </p>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <button
              onClick={handleSubmit}
              disabled={isLoading || !edChecked || !restaurantChecked}
              className={styles.button}
            >
              {isLoading ? <Loader width="20px" height="20px" /> : "დაჯავშნა"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservForm;
