"use client";

import styles from "./reserve.module.scss";
import { useEffect, useState } from "react";
import React from "react";
import BranchPicker from "./branch-picker/branch-picker";
import DateGuestPicker from "./date-guest-picker/date-guest-picker";
import AvailableTimesPicker from "./available-times-picker/available-times-picker";
import ReservForm from "./reserv-form/reserv-form";
import { AvailableTimeModel, getAvailableTimes } from "../../api/reservation";
import {
  Branch,
  RestauranntToReserve,
  getRestaurantToReserve,
} from "../../api/restaurant";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../loader/loder";

interface FormData {
  date: Date;
  guest: number;
  selectedTime?: AvailableTimeModel;
}

interface ReserveProps {
  subdomain?: string | null;
}

const Reserve: React.FC<ReserveProps> = ({ subdomain }) => {
  const { domainName } = useParams<{
    domainName: string;
  }>();

  const restaurantDomainName = subdomain || domainName;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const branchFromQuery = queryParams.get("branch");

  const [restaurant, setRestaurant] = useState<RestauranntToReserve>();
  const [loadingRestaurant, setLoadingRestaurant] = useState<boolean>(true);
  const [choosedBranch, setChoosedBranch] = useState<Branch | null>();

  useEffect(() => {
    if (restaurantDomainName) {
      getRestaurantToReserve(restaurantDomainName)
        .then((data) => {
          setRestaurant(data);
          if (branchFromQuery) {
            setChoosedBranch(
              data.branches.find((b) => b.domainName == branchFromQuery)
            );
          } else {
            // setChoosedBranch(
            //   data.branches.length == 1 ? data.branches[0] : null
            // );

            if (data.branches.length == 1) handleChooseBranch(data.branches[0]);
          }
        })
        .catch(() => {
          console.log("Restaurant not found");
        })
        .finally(() => {
          setLoadingRestaurant(false);
        });
    }
  }, [restaurantDomainName]);

  // const [choosedBranch, setChoosedBranch] = useState<Branch | null>(
  //   branch ?? restaurant == null
  //     ? null
  //     : restaurant.branches.length == 1
  //       ? restaurant.branches[0]
  //       : null
  // );

  const [step, setStep] = useState<number>(branchFromQuery ? 1 : 0);

  const [formData, setFormData] = useState<FormData>({
    date: new Date(),
    guest: 1,
  });

  const handleDateChange = (date: Date) => {
    setFormData({
      ...formData,
      date: date,
    });
  };

  const handleGuestChange = (guest: number) => {
    setFormData({
      ...formData,
      guest: guest,
    });
  };

  const handleChooseTime = (time: AvailableTimeModel) => {
    if (time.id == formData.selectedTime?.id) {
      setFormData({
        ...formData,
        selectedTime: undefined,
      });

      return;
    }

    const combinedDateTime = new Date(formData.date);
    const timeDate = new Date(time.time);

    combinedDateTime.setHours(timeDate.getHours(), timeDate.getMinutes());

    setFormData({
      ...formData,
      selectedTime: time,
      date: combinedDateTime,
    });

    setStep(3);
  };

  const [availableTimes, setAvailableTimes] = useState<AvailableTimeModel[]>(
    []
  );

  const [isTimesLoading, setIsTimesLoading] = useState<boolean>(false);
  const loadAvailableTimes = () => {
    if (!choosedBranch) return;

    setIsTimesLoading(true);
    getAvailableTimes(choosedBranch?.id, formData.date, formData.guest)
      .then((data) => {
        setAvailableTimes(data);
        setStep(2);
        setIsTimesLoading(false);
      })
      .then(() => {
        setIsTimesLoading(false);
      });
  };

  const handleChooseBranch = (branch: Branch) => {
    if (branch) {
      setChoosedBranch(branch);
      setStep(1);
    }
  };

  return (
    <>
      {loadingRestaurant ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader height="40px" width="40px" />
        </div>
      ) : (
        restaurant && (
          <div className={styles.card}>
            {step === 0 && (
              <BranchPicker
                restaurant={restaurant}
                branchDomainName={branchFromQuery}
                onChooseBranch={handleChooseBranch}
              />
            )}
            {step === 1 && (
              <DateGuestPicker
                date={formData.date}
                setDate={handleDateChange}
                guest={formData.guest}
                setGuest={handleGuestChange}
                isLoading={isTimesLoading}
                onSubmited={loadAvailableTimes}
                restaurant={restaurant}
                branch={branchFromQuery}
              />
            )}
            {step === 2 && (
              <AvailableTimesPicker
                date={formData.date}
                guest={formData.guest}
                restaurant={restaurant}
                branch={branchFromQuery}
                availableTimes={availableTimes}
                onChooseTime={handleChooseTime}
                onBack={() => {
                  setStep(step - 1);
                }}
              />
            )}
            {step == 3 && choosedBranch && (
              <ReservForm
                date={formData.date}
                guest={formData.guest}
                restaurant={restaurant}
                branch={choosedBranch}
                onBack={() => {
                  setStep(step - 1);
                }}
              />
            )}
            <div className={styles.footer}>
              Powered by{" "}
              <a href={"http://easydine.ge"} target="_blank">
                EasyDine
              </a>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Reserve;
