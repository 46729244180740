import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { AuthModalProvider } from "./components/auth/auth-modal-context";
import Reserve from "./components/reserve/reserve";
import TestPage from "./test";

// Utility to extract subdomain
const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");

  console.log(parts);
  if (parts.length >= 2) {
    return parts[0];
  }
  return null;
};

const SubdomainHandler: React.FC<{
  setSubdomain: (subdomain: string | null) => void;
}> = ({ setSubdomain }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const subdomain = getSubdomain();
    setSubdomain(subdomain);
    // if (subdomain) {
    //   navigate(`/${subdomain}`);
    // }
  }, [navigate, setSubdomain]);

  return null;
};

const App: React.FC = () => {
  const [subdomain, setSubdomain] = useState<string | null>(null);

  return (
    <AuthModalProvider>
      <Router>
        <SubdomainHandler setSubdomain={setSubdomain} />
        <Routes>
          <Route path="/:domainName" element={<Reserve />} />
          <Route
            path="*"
            element={
              !subdomain ? (
                <TestPage subdomain={subdomain} />
              ) : (
                <Reserve subdomain={subdomain} />
              )
            }
          />
        </Routes>
      </Router>
    </AuthModalProvider>
  );
};

export default App;
