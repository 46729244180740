import { AuthResponse } from "../api/auth/account";

export const authService = {
  login: (authResponse: AuthResponse) => {
    authService.logout();
    localStorage.setItem("userId", authResponse.userId.toString());
    localStorage.setItem("userName", authResponse.userName);
    localStorage.setItem("firstName", authResponse.firstName);
    localStorage.setItem("lastName", authResponse.lastName);
    localStorage.setItem("email", authResponse.email);
    localStorage.setItem("phoneNumber", authResponse.phoneNumber);
    localStorage.setItem("profilePicture", authResponse.profilePicture);
    localStorage.setItem("expires", authResponse.expires.toString());
    localStorage.setItem("token", authResponse.token);
    localStorage.setItem("refreshToken", authResponse.refreshToken);
    localStorage.setItem("authSource", authResponse.authSource.toString());
  },

  getUserInfo: (): any => {
    const user = {
      userId: localStorage.getItem("userId"),
      userName: localStorage.getItem("userName"),
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
      email: localStorage.getItem("email"),
      phoneNumber: localStorage.getItem("phoneNumber"),
      profilePicture: localStorage.getItem("profilePicture"),
    };
    return user;
  },

  refreshToken: (authResponse: AuthResponse) => {
    localStorage.setItem("expires", authResponse.expires.toString());
    localStorage.setItem("token", authResponse.token);
    localStorage.setItem("refreshToken", authResponse.refreshToken);
  },

  isLoggedIn: (): boolean => {
    return localStorage.getItem("token") !== null;
  },

  getFullName: (): string => {
    return (
      `${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}` ??
      ""
    );
  },

  getProfilePicture: (): string => {
    const profilePicture = localStorage.getItem("profilePicture");
    if (!profilePicture) return "";
    return profilePicture;
  },

  setProfilePicture: (profilePicture: string) => {
    localStorage.setItem("profilePicture", profilePicture);
  },

  getToken: (): string | null => {
    return localStorage.getItem("token") ?? "";
  },

  getRefreshToken: (): string | null => {
    return localStorage.getItem("refreshToken") ?? "";
  },

  getUserId: (): number => {
    const userId = localStorage.getItem("userId");
    if (!userId) return 0;
    return parseInt(userId);
  },

  getAuthSource: (): number => {
    const authSource = localStorage.getItem("authSource");
    return authSource ? parseInt(authSource) : 1;
  },

  logout: () => {
    const keysToRemove = [
      "userId",
      "expires",
      "userName",
      "firstName",
      "lastName",
      "profilePicture",
      "token",
      "refreshToken",
      "authSource",
      "accessToken", // Remove unnecessary keys
      "authStatus", // Remove unnecessary keys
    ];

    keysToRemove.forEach((key) => localStorage.removeItem(key));
  },
};
