import React, { useState } from "react";
import Loader from "../../loader/loder";
import { sendOtp } from "../../../api/auth/account";
import style from "./phone-input.module.scss";

interface OTPInputProps {
  moveNext: (phoneNumber: string) => void;
}

const PhoneInput: React.FC<OTPInputProps> = ({ moveNext }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleButtonClick = () => {
    setLoading(true);
    sendOtp(phoneNumber)
      .then(() => {
        moveNext(phoneNumber);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <span
          style={{
            fontSize: "10px",
            color: "#2F372D",
            padding: "10px",
          }}
        >
          ტელეფონის ნომერი
        </span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#EDD0AA",
            border: "2px solid #0D5730",
            borderRadius: "10px",
            color: "#0d5730",
            padding: "5px",
          }}
        >
          <span style={{ marginRight: "10px", color: "#0d5730" }}>(+995)</span>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*$/.test(inputValue)) {
                setPhoneNumber(inputValue);
              }
            }}
            style={{
              border: "none",
              background: "transparent",
              color: "#0d5730",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <button
          className={style.button}
          onClick={handleButtonClick}
          disabled={!phoneNumber || loading}
        >
          {loading ? (
            <Loader height="8px" width="8px" />
          ) : (
            <span style={{ color: "#0d5730" }}>შესვლა</span>
          )}
        </button>
      </div>

      <div>
        <a
          style={{
            fontSize: "14px",
            color: "#2F372D",
            fontWeight: "500",
            textDecoration: "none",
          }}
          href="https://easydine.ge/auth/signup"
          onMouseOver={(e: any) => {
            e.target.style.textDecoration = "underline";
          }}
          onMouseOut={(e: any) => {
            e.target.style.textDecoration = "none";
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          არ გაქვთ ანგარიში? გაიარე რეგისტრაცია
        </a>
      </div>
    </>
  );
};

export default PhoneInput;
